/* eslint-disable max-len */
import React from 'react';
import xw from 'twin.macro';

const styles = {
  heading: xw`text-3xl leading-9 tracking-tight font-extrabold sm:text-4xl sm:leading-10 mb-2`,
  subHeading: xw`mt-2 text-xl leading-7 font-semibold`,
  tag: xw`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-indigo-200 text-black dark:text-white dark:bg-purple-600`,
};

function Component() {
  return (
    <div className="relative max-w-lg px-4 pt-6 pb-20 mx-auto sm:px-6 lg:pt-12 lg:pb-28 lg:px-8 lg:max-w-7xl">
      <h1 className="text--color-heading-1" css={[styles.heading]}>
        Publications
      </h1>
      <ul className="grid mt-6 border-t-2 lg:grid-cols-2">
        <li className="p-8 border-b-2 lg:border-r-2">
          <div className="mb-4">
            <time css={[styles.tag]} dateTime="2018">
              1985
            </time>
          </div>
          <h2 className="text--color-heading-1" css={[styles.subHeading]}>
            Subang Institute Technology, Malaysia
          </h2>
          <ul className="mt-3 text-base leading-6 list-disc text--color-body-2">
            <li className="mb-3">
              Building construction technical reference handbook for TVET -
              Diploma level College students.
            </li>
          </ul>
        </li>

        <li className="p-8 border-b-2">
          <div className="mb-4">
            <time css={[styles.tag]} dateTime="2018">
              2009-2013
            </time>
          </div>
          <h2 className="text--color-heading-1" css={[styles.subHeading]}>
            UN IOM Afghanistan
          </h2>
          <ul className="mt-3 text-base leading-6 list-disc text--color-body-2">
            <li className="mb-3">
              Quality Controls Manual for Humanitarian Projects.
            </li>
            <li className="mb-3">
              Construction Safety. Environmental Management and Mitigation Plan
              for Humanitarian Projects.
            </li>
          </ul>
        </li>

        <li className="p-8 border-b-2 lg:border-r-2">
          <div className="mb-4">
            <time css={[styles.tag]} dateTime="2018">
              2014-2020
            </time>
          </div>
          <h2 className="text--color-heading-1" css={[styles.subHeading]}>
            UN IOM Iraq
          </h2>
          <ul className="mt-3 text-base leading-6 list-disc text--color-body-2">
            <li className="mb-3">
              Construction Manual - Best Practices in Construction And
              Rehabilitation of Community Development Infrastructure projects.
            </li>
            <li className="mb-3">
              Emergency shelter rehabilitation and upgrading works reference
              handbook for technical field workers.
            </li>
            <li className="mb-3">
              WASH (Water And Sanitation Projects) for emergency and
              reconstruction community development projects.
            </li>
            <li className="mb-3">
              Bill of Quantities (BOQ) reference handbook for technical
              engineers in humanitarian relief projects.
            </li>
            <li className="mb-3">
              CCCM Camp Care and Maintainance plan (on site and off site
              critical shelter projects).
            </li>
            <li className="mb-3">
              Engineering and technical guidelines in preparing construction
              documents for humanitarian relief projects (Technical drawings,
              Technical specifications, Bill of Qualities, Work implementation
              plan/schedule, Tender bid documents).
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export const Main = Component;
